import React from "react";
import { Dropdown, Avatar, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useAuthContext } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";

// import styles from "./AccountDropdown.module.scss";

const AccountDropdown = () => {
  const { logOut } = useAuthContext();
  const history = useHistory();

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          logOut();
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Avatar
        style={{ cursor: "pointer", marginRight: 32 }}
        size="small"
        icon={<UserOutlined />}
      />
    </Dropdown>
  );
};

export default AccountDropdown;
