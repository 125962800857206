import AddCompetition from "../pages/AddCompetition/AddCompetition";
import CompetitionDetail from "../pages/CompetitionDetail/CompetitionDetail";
import Competitions from "../pages/Competitions/Competitions";

const competitionRoutes = [
  { path: "/competitions", component: Competitions },
  { path: "/competitions/add", component: AddCompetition },
  { path: "/competitions/:id", component: CompetitionDetail },
];

export default competitionRoutes;
