import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "@firebase/auth";
import { initializeApp } from "firebase/app";
import environment from "../config/environment";

const { firebase } = environment;

const firebaseConfig = {
  apiKey: firebase.API_KEY,
  authDomain: firebase.AUTH_DOMAIN,
  projectId: firebase.projectId,
  storageBucket: firebase.STORAGE_BUCKET,
  messagingSenderId: firebase.MESSAGING_SENDER_ID,
  appId: firebase.APP_ID,
};

initializeApp(firebaseConfig);

const auth = getAuth();

const login = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const onAuthChange = (setCurrentUser, setIsLoading) => {
  return onAuthStateChanged(auth, async (user) => {
    setCurrentUser(user);
    setIsLoading(false);
  });
};

const getIdToken = async () => {
  const user = auth.currentUser;
  const token = await user.getIdToken();
  return token;
};

const sendResetEmail = async (email) => {
  await sendPasswordResetEmail(auth, email);
};

const changePassword = async (currentPassword, newPassword) => {
  const user = auth.currentUser;
  const email = user.email;
  const credential = EmailAuthProvider.credential(email, currentPassword);
  await reauthenticateWithCredential(user, credential);
  await updatePassword(user, newPassword);
};

const logOut = () => {
  return signOut(auth);
};

const signUp = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export {
  onAuthChange,
  login,
  logOut,
  signUp,
  getIdToken,
  sendResetEmail,
  changePassword,
};
