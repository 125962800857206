import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Space,
  PageHeader,
  InputNumber,
  message,
  Select,
} from "antd";
import { Link } from "react-router-dom";
import waitTime from "../../../../common/utils/waitTime";
import { useParams } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import Container from "../../../../common/components/Container/Container";
import userTypes from "../../constants/userTypes";
import DeleteUserModal from "../../components/DeleteUserModal/DeleteUserModal";
import { useHistory } from "react-router-dom";

const { Option } = Select;

const UserDetail = () => {
  const [form] = Form.useForm();
  const { id: userId } = useParams();
  const { user, updateUser, deleteUser } = useUser(userId);
  const { featureFlags } = useFeatureFlags();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const history = useHistory();

  const handleDeleteUser = async () => {
    setIsDeletingUser(true);
    try {
      await waitTime(2000);
      await deleteUser(userId)
      message.success("User sucessfully deleted!");
    } catch (ex) {
      message.error("Could not delete user!");
    }
    setIsDeleteUserModalOpen(false);
    setIsDeletingUser(false);
    history.push("/users");
  };

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        athleteSpots: user.athleteSpots,
        featureFlagIds: user.featureFlags?.map((ff) => ff.id),
      });
    }
  }, [user]);

  const handleUpdateUserSpots = async (values) => {
    setIsUpdating(true);
    await waitTime(2000);
    try {
      const updatedUser = {
        id: userId,
        athleteSpots: values.athleteSpots,
        featureFlagIds: values.featureFlagIds,
        userType: userTypes.headCoach,
      };
      await updateUser(updatedUser);
      message.success("Successfully updated user!");
    } catch (err) {
      message.error("There was a problem updating the user!");
    }
    setIsUpdating(false);
  };

  return (
    <>
      <DeleteUserModal
        userEmail={user?.email}
        isVisible={isDeleteUserModalOpen}
        onCancel={() => setIsDeleteUserModalOpen(false)}
        onDeleteUser={handleDeleteUser}
        isDeletingUser={isDeletingUser}
      />
      <Container noPadding>
        <PageHeader className="site-page-header" title={"User Detail"} />
      </Container>
      <Container>
        <Form
          form={form}
          name="editUser"
          onFinish={handleUpdateUserSpots}
          requiredMark={true}
          layout={"vertical"}
          scrollToFirstError
        >
          <Form.Item name="firstName" label="First name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="lastName" label="Last name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="athleteSpots" label="Roster Spots">
            <InputNumber />
          </Form.Item>
          <Form.Item name="featureFlagIds" label="Feature Flag(s)">
            <Select mode="multiple">
              {featureFlags &&
                featureFlags.map((featureFlag) => {
                  return (
                    <Option key={featureFlag.id} value={featureFlag.id}>
                      {featureFlag.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Link to="/users">
                <Button type="secondary">Cancel</Button>
              </Link>
              <Button type="primary" htmlType="submit" loading={isUpdating}>
                {"Update"}
              </Button>
              <Button
                type="danger"
                loading={isDeletingUser}
                onClick={() => setIsDeleteUserModalOpen(true)}
              >
                Delete
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Container>
    </>
  );
};

export default UserDetail;
