import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Space,
  PageHeader,
  DatePicker,
  message,
  Upload,
} from "antd";
import { Link } from "react-router-dom";
import waitTime from "../../../../common/utils/waitTime";
import { useParams } from "react-router-dom";
import Container from "../../../../common/components/Container/Container";
import dayjs from "dayjs";
import useCompetitions from "../../hooks/useCompetitions";
import { useHistory } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import EntryModal from "../../components/EntryModal/EntryModal";

const { RangePicker } = DatePicker;

const submissionTypes = {
  result: "Result",
  report: "Report",
};

const CompetitionDetail = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [competition, setCompetition] = useState(null);
  const [submissionType, setSubmissionType] = useState(submissionTypes.report);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEntryModalVisible, setIsEntryModalVisible] = useState(false);
  const { updateCompetition, uploadLogo } = useCompetitions();
  const history = useHistory();
  const [fileList, setFileList] = useState([]);
  const numEntries = competition?.entries.length;

  useEffect(() => {
    const getCompetition = async () => {
      try {
        const competition = await axios.get(`/competition/${id}`);
        setCompetition(competition);
      } catch (ex) {}
    };
    getCompetition();
  }, []);

  const handleUpdateCompetition = async (values) => {
    setIsUpdating(true);
    await waitTime(2000);
    try {
      const { date, sponsorshipDescription, sponsorshipName, sponsorshipLogo } =
        values;
      const startDate = date[0].toISOString();
      const endDate = date[1].toISOString();

      let logoUrl = competition.sponsorshipLogo;

      if (sponsorshipLogo && sponsorshipLogo.length === 0) {
        logoUrl = null;
      } else if (
        sponsorshipLogo &&
        sponsorshipLogo.length &&
        sponsorshipLogo.length > 0 &&
        sponsorshipLogo[0].uid !== "-1"
      ) {
        const imageFile = sponsorshipLogo[0].originFileObj;
        logoUrl = await uploadLogo(imageFile);
      }

      const updateCompetitionDto = {
        competitionId: id,
        sponsorshipDescription,
        sponsorshipName,
        signUpUrl: values.signUpUrl,
        startDate,
        endDate,
        sponsorshipLogo: logoUrl,
      };
      await updateCompetition(id, updateCompetitionDto);
      message.success("Successfully updated competition!");
      history.push("/competitions");
    } catch (err) {
      console.log(err);
      message.error("There was a problem updating the competition");
    }
    setIsUpdating(false);
  };

  useEffect(() => {
    if (competition) {
      const submissionType = competition.submissionType;
      const startDate = dayjs.utc(competition.startDate).local();
      const endDate = dayjs.utc(competition.endDate).local();
      console.log("COMPETITION", competition);
      const fields = {
        name: competition.name,
        submissionType,
        status: competition.status,
        sex: competition.sex,
        sponsorshipName: competition.sponsorshipName,
        sponsorshipDescription: competition.sponsorshipDescription,
        zipCode: competition.zipCode,
        date: [startDate, endDate],
      };

      if (competition.sponsorshipLogo) {
        const existingLogo = [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: competition.sponsorshipLogo,
          },
        ];
        console.log("setting file list");
        console.log("existingLogo", existingLogo);
        fields.sponsorshipLogo = existingLogo;
        setFileList(existingLogo);
      } else setFileList([]);

      if (submissionType === submissionTypes.result) {
        const units = competition.isMetric ? "Metric" : "Imperial";

        fields.eventType = competition.eventTypeDisplayName;
        fields.distance = competition.distance;
        fields.isMetric = units;
      }

      setSubmissionType(submissionType);

      form.setFieldsValue(fields);
    }
  }, [competition]);

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  let ResultBasedFormComponents = null;
  if (submissionType === submissionTypes.result)
    ResultBasedFormComponents = (
      <>
        <Form.Item name="eventType" label="Event Type">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item name="isMetric" label="Units">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item name="distance" label="Distance">
          <Input disabled={true} />
        </Form.Item>
      </>
    );

  return (
    <>
      <EntryModal
        isVisible={isEntryModalVisible}
        onCancel={() => setIsEntryModalVisible(false)}
        entries={competition?.entries}
      />
      <Container noPadding>
        <PageHeader className="site-page-header" title={"Competition Detail"} />
      </Container>
      <Container>
        <Form
          form={form}
          name="editUser"
          requiredMark={true}
          layout={"vertical"}
          scrollToFirstError
          onFinish={handleUpdateCompetition}
        >
          <Form.Item name="name" label="Name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="submissionType" label="Submmission Type">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="sex" label="Sex">
            <Input disabled={true} />
          </Form.Item>
          {ResultBasedFormComponents}
          <Form.Item name="status" label="Status">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="zipCode" label="ZIP Code">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            name="sponsorshipLogo"
            label="Sponsorship Logo"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) return e;
              return e && e.fileList;
            }}
          >
            <Upload
              listType="picture-card"
              onChange={({ fileList: newFileList }) => setFileList(newFileList)}
              beforeUpload={(file) => false}
              showUploadList={{
                showPreviewIcon: false,
              }}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item name={`date`} label={`Start Date / End Date`}>
            <RangePicker style={{ width: "100%" }} format={"MM/DD/YYYY"} />
          </Form.Item>
          <Form.Item name="sponsorshipName" label="Sponsorship Name">
            <Input />
          </Form.Item>
          <Form.Item
            name="sponsorshipDescription"
            label="Sponsorship Description"
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button onClick={() => setIsEntryModalVisible(true)}>
                View Entries ({numEntries})
              </Button>
              <Link to="/competitions">
                <Button>Cancel</Button>
              </Link>
              <Button type="primary" htmlType="submit" loading={isUpdating}>
                Update
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Container>
    </>
  );
};

export default CompetitionDetail;
