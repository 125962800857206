import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const path = "/featureflag";

const useFeatureFlags = () => {
  const { data: featureFlags, error } = useSWR(path, axios.get);
  const { mutate } = useSWRConfig();

  const addFeatureFlag = async (featureFlag) => {
    await axios.post(path, featureFlag);
    mutate(path);
  };

  const deleteFeatureFlag = async (id) => {
    await axios.delete(`${path}/${id}`);
    mutate(path);
  };

  return {
    featureFlags,
    isLoading: !error && !featureFlags,
    isError: error,
    deleteFeatureFlag,
    addFeatureFlag,
  };
};

export default useFeatureFlags;
