import React from "react";
import { Result, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Paragraph, Text } = Typography;

const Feedback = ({ status, title, subTitle, errorMessage, errors, extra }) => {
  let errorSection = null;
  console.log("errors", errors);
  if (errors) {
    errorSection = (
      <div className="desc">
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            {errorMessage}
          </Text>
        </Paragraph>
        {errors
          ? errors?.map((error) => (
              <Paragraph key={error}>
                <CloseCircleOutlined style={{ color: "red" }} /> {error}
              </Paragraph>
            ))
          : null}
      </div>
    );
  }
  return (
    <Result status={status} title={title} subTitle={subTitle} extra={extra}>
      {errorSection}
    </Result>
  );
};

export default Feedback;
