import Login from "../pages/Login/Login";
import Signup from "../pages/Signup/Signup";

const accessRoutes = [
  { path: "/login", component: Login },
  { path: "/signup", component: Signup },
//   { path: "/forgotpassword", component: ForgotPassword },
];

export default accessRoutes;
