import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import Feedback from "../../../../common/components/Feedback/Feedback";

const AddPartnerSubmission = ({ submissionErrors, onTryAgain }) => {
  if (submissionErrors) {
    return (
      <Feedback
        status="error"
        title="Add Partner Failed"
        subTitle={
          "Please check and modify the following information before resubmitting"
        }
        errorMessage={
          "The partner you were trying to add has the following errors"
        }
        errors={submissionErrors}
        extra={[
          <Link to="/partner" key="viewPartnersButton">
            <Button type="primary">View Partners</Button>
          </Link>,
          <Button
            key="tryAgainButton"
            onClick={() => {
              onTryAgain();
            }}
          >
            Try again
          </Button>,
        ]}
      />
    );
  }
  return (
    <Feedback
      status="success"
      title={`Successfully Added Partner!`}
      extra={[
        <Link to="/partner" key="viewPartnersButton">
          <Button type="primary">View Partners</Button>
        </Link>,
        <Button
          key="addAnotherPartnerButton"
          onClick={() => {
            onTryAgain();
          }}
        >
          Add Another Partner
        </Button>,
      ]}
    />
  );
};

export default AddPartnerSubmission;
