import React, { useState } from "react";
import { Steps } from "antd";
import Container from "../../../../common/components/Container/Container";
import waitTime from "../../../../common/utils/waitTime";
import styles from "./AddPartner.module.scss";
import usePartners from "../../hooks/usePartners";
import AddPartnerForm from "../../components/AddPartnerForm/AddPartnerForm";
import AddPartnerSubmission from "../../components/AddPartnerSubmission/AddPartnerSubmission";

const { Step } = Steps;

const steps = [
  {
    title: "General Information",
    content: AddPartnerForm,
  },
  {
    title: "Submission",
    content: AddPartnerSubmission,
  },
];

const AddPartner = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [submissionErrors, setSubmissionErrors] = useState(null);
  const { addPartner, uploadLogo } = usePartners();

  const handleNextStep = async () => {
    await waitTime(2000);
    setIsLoading(false);
    setCurrentStep(currentStep + 1);
  };

  const handleTryAgain = () => {
    setCurrentStep(0);
    setSubmissionErrors(null);
  };

  const handleAddPartner = async (partner, logo) => {
    setIsLoading(true);
    try {
      let logoUrl = null;
      if (logo && logo.length && logo.length > 0 && logo[0].uid !== "-1") {
        const imageFile = logo[0].originFileObj;
        console.log(imageFile);
        logoUrl = await uploadLogo(imageFile);
      }

      partner.logoUrl = logoUrl;

      await addPartner(partner);
    } catch (errors) {
      setSubmissionErrors(errors);
    }
    await handleNextStep();
  };

  const StepContentComponent = steps[currentStep].content;
  let stepContentComponentProps = null;
  switch (currentStep) {
    case 0:
      stepContentComponentProps = {
        onNextStep: handleAddPartner,
        isLoading,
      };
      break;
    case 1:
      stepContentComponentProps = {
        submissionErrors,
        onTryAgain: handleTryAgain,
      };
      break;
    default:
      break;
  }

  return (
    <Container>
      <Steps current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className={styles.stepsContent}>
        <StepContentComponent {...stepContentComponentProps} />
      </div>
    </Container>
  );
};

export default AddPartner;
