import React from "react";
import { Route, Switch } from "react-router";
import AuthRoute from "./common/routes/AuthRoute";
import AuthRedirectRoute from "./common/routes/AuthRedirectRoute";
import accessRoutes from "./features/access/routes/accessRoutes";
import userRoutes from "./features/users/routes/userRoutes";
import competitionRoutes from "./features/competitions/routes/competitionRoutes";

import "./App.css";
import partnerRoutes from "./features/partners/routes/partnerRoutes";

const App = () => {
  const authenticatedRoutes = [
    ...userRoutes,
    ...competitionRoutes,
    ...partnerRoutes,
  ];
  const routes = [...accessRoutes];

  return (
    <Switch>
      {routes.map((route) => (
        <Route
          key={route.path}
          exact
          path={route.path}
          component={route.component}
        />
      ))}
      {authenticatedRoutes.map((authenticatedRoute) => (
        <AuthRoute
          key={authenticatedRoute.path}
          exact
          path={authenticatedRoute.path}
          component={authenticatedRoute.component}
        />
      ))}
      <AuthRedirectRoute exact path="/" />
    </Switch>
  );
};

export default App;
