const staging = {
    API_URL: "https://admin-api.dashrsystems-dev.com/api",
    firebase: {
      API_KEY: "AIzaSyDYP-TOUUvXmu0zB3Yose63bXY4CCfUceY",
      AUTH_DOMAIN: "admin-dashr-staging.firebaseapp.com",
      PROJECT_ID: "admin-dashr-staging",
      STORAGE_BUCKET: "admin-dashr-staging.appspot.com",
      MESSAGING_SENDER_ID: "829105240846",
      APP_ID: "1:829105240846:web:fc26ae066bd4069912f55f",
    },
  };
  
  const production = {
    API_URL: "https://admin-api.dashrsystems.com/api",
    firebase: {
      API_KEY: "AIzaSyCaM4DHOLa-39O0lcUpprCoxZkQyYvnVkE",
      AUTH_DOMAIN: "admin-dashr-prod.firebaseapp.com",
      PROJECT_ID: "admin-dashr-prod",
      STORAGE_BUCKET: "admin-dashr-prod.appspot.com",
      MESSAGING_SENDER_ID: "643098757155",
      APP_ID: "1:643098757155:web:ccc94a47096c19f3f1c996",
    },
  };
  
  const environment =
    process.env.REACT_APP_STAGE === "production" ? production : staging;
  
  export default environment;
  