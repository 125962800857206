import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const useUsers = () => {
  const { data: users, error } = useSWR("/user", axios.get);
  const { mutate } = useSWRConfig();

  return {
    users,
    isLoading: !error && !users,
    isError: error,
  };
};

export default useUsers;
