import AddPartner from "../pages/AddPartner/AddPartner";
import PartnerDetail from "../pages/PartnerDetail/PartnerDetail";
import Partners from "../pages/Partners/Partners";

const partnerRoutes = [
  { path: "/partner", component: Partners },
  { path: "/partner/add", component: AddPartner },
  { path: "/partner/:id", component: PartnerDetail },
];

export default partnerRoutes;
