const generateAccessErrorMessage = (firebaseCode) => {
  switch (firebaseCode) {
    case "auth/user-not-found":
      return "There is not an account with this email";
    case "auth/wrong-password":
      return "The password you have entered is incorrect";
    case "auth/email-already-in-use":
      return "There is already an account with this email";
    case "auth/weak-password":
      return "Password should be atleast 6 characters";
    default:
      return;
  }
};

export default generateAccessErrorMessage;
