import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { TeamOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useMenu } from "../../contexts/MenuContext";
import runningManLogo from "../../../assets/logo.svg";
import dashrLogo from "../../../assets/dashr_logo.png";
import styles from "./SideMenu.module.scss";

const { Sider } = Layout;

const logos = {
  runningMan: { path: runningManLogo, style: { height: 50 } },
  dashrText: { path: dashrLogo, style: { height: 75 } },
};

const SideMenu = () => {
  const { selectedMenu, setSelectedMenu } = useMenu();
  const [logo, setLogo] = useState(logos.dashrText);
  const history = useHistory();

  return (
    <Sider
      breakpoint="lg"
      collapsible
      onCollapse={(collapsed) => {
        if (collapsed) setLogo(logos.runningMan);
        else setLogo(logos.dashrText);
      }}
    >
      <div className={styles.logo}>
        <img src={logo.path} style={logo.style} alt="Logo" />
      </div>
      <Menu
        theme="dark"
        selectedKeys={selectedMenu}
        mode="vertical"
        triggerSubMenuAction={"click"}
      >
        <Menu.Item
          key="1"
          icon={<TeamOutlined />}
          onClick={() => {
            setSelectedMenu(["1"]);
            history.push("/users");
          }}
        >
          Users
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<TeamOutlined />}
          onClick={() => {
            setSelectedMenu(["2"]);
            history.push("/competitions");
          }}
        >
          Competitions
        </Menu.Item>
        <Menu.Item
          key="3"
          icon={<TeamOutlined />}
          onClick={() => {
            setSelectedMenu(["3"]);
            history.push("/partner");
          }}
        >
          Partners
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SideMenu;
