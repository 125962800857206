import React, { useState } from "react";
import { Steps } from "antd";

import AddCompetitionForm from "../../components/AddCompetitionForm/AddCompetitionForm";
import AddCompetitionSubmission from "../../components/AddCompetitionSubmission/AddCompetitionSubmission";
import Container from "../../../../common/components/Container/Container";
import waitTime from "../../../../common/utils/waitTime";
import styles from "./AddCompetition.module.scss";
import useCompetitions from "../../hooks/useCompetitions";

const { Step } = Steps;

const steps = [
  {
    title: "General Information",
    content: AddCompetitionForm,
  },
  {
    title: "Submission",
    content: AddCompetitionSubmission,
  },
];

const AddCompetition = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [submissionErrors, setSubmissionErrors] = useState(null);
  const { addCompetition, uploadLogo } = useCompetitions();
  const [fileList, setFileList] = useState([]);

  const handleNextStep = async () => {
    await waitTime(2000);
    setIsLoading(false);
    setCurrentStep(currentStep + 1);
  };

  const handleTryAgain = () => {
    setCurrentStep(0);
    setSubmissionErrors(null);
  };

  const handleAddCompetition = async (competition, logo) => {
    setIsLoading(true);
    try {
      let logoUrl = null;
      if (logo && logo.length && logo.length > 0 && logo[0].uid !== "-1") {
        const imageFile = logo[0].originFileObj;
        console.log(imageFile);
        logoUrl = await uploadLogo(imageFile);
      }

      competition.sponsorshipLogo = logoUrl;

      await addCompetition(competition);
    } catch (errors) {
      setSubmissionErrors(errors);
    }
    await handleNextStep();
  };

  const StepContentComponent = steps[currentStep].content;
  let stepContentComponentProps = null;
  switch (currentStep) {
    case 0:
      stepContentComponentProps = {
        onNextStep: handleAddCompetition,
        isLoading,
      };
      break;
    case 1:
      stepContentComponentProps = {
        submissionErrors,
        onTryAgain: handleTryAgain,
      };
      break;
    default:
      break;
  }

  return (
    <Container>
      <Steps current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className={styles.stepsContent}>
        <StepContentComponent {...stepContentComponentProps} />
      </div>
    </Container>
  );
};

export default AddCompetition;
