import React, { useState } from "react";
import { Button, Drawer, List, Popconfirm, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import waitTime from "../../../../common/utils/waitTime";
import useFeatureFlags from "../../hooks/useFeatureFlags";

const FeatureFlagDrawer = ({ isVisible, onClose, onAddFeatureFlag }) => {
  const { featureFlags, deleteFeatureFlag } = useFeatureFlags();
  const [isPopConfirmVisible, setIsPopConfirmVisible] = useState(null);
  const [isDeletingFeatureFlag, setIsDeletingFeatureFlag] = useState(false);

  const handleDeleteFeatureFlag = async (id) => {
    setIsDeletingFeatureFlag(true);
    try {
      await waitTime(2000);
      await deleteFeatureFlag(id);
      message.success(`Successfully deleted feature flag!`);
      setIsPopConfirmVisible(null);
    } catch (err) {
        console.error('err', err)
    }
    setIsDeletingFeatureFlag(false);
  };

  return (
    <>
      <Drawer
        title="Manage Feature Flags"
        placement="right"
        onClose={onClose}
        visible={isVisible}
        footer={
          <Button type="primary" block onClick={onAddFeatureFlag}>
            <PlusOutlined />
            Add
          </Button>
        }
      >
        <List
          itemLayout="horizontal"
          dataSource={featureFlags}
          renderItem={(featureFlag) => (
            <List.Item
              actions={[
                <Popconfirm
                  title={`Are you sure you want to delete ${featureFlag.name}?`}
                  onCancel={() => setIsPopConfirmVisible(null)}
                  visible={isPopConfirmVisible === featureFlag.id}
                  onConfirm={() =>
                    handleDeleteFeatureFlag(featureFlag.id, featureFlag.name)
                  }
                  okButtonProps={{ loading: isDeletingFeatureFlag }}
                  okText="Yes"
                  cancelText="No"
                >
                  <a onClick={() => setIsPopConfirmVisible(featureFlag.id)}>
                    Delete
                  </a>
                </Popconfirm>,
              ]}
            >
              {featureFlag.name}
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
};

export default FeatureFlagDrawer;
