import React, { useState } from "react";
import { Table, PageHeader, Input, Button, message } from "antd";
import Container from "../../../../common/components/Container/Container";
import styles from "./Users.module.scss";
import useUsers from "../../hooks/useUsers";
import { useHistory } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import FeatureFlagDrawer from "../../components/FeatureFlagDrawer/FeatureFlagDrawer";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import waitTime from "../../../../common/utils/waitTime";
import AddFeatureFlagModal from "../../components/AddFeatureFlagModal/AddFeatureFlagModal";

const { Search } = Input;

const Users = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isFFDrawerVisible, setIsFFDrawerVisible] = useState(false);
  const [isFFModalVisible, setIsFFModalVisible] = useState(false);
  const [isAddingFF, setIsAddingFF] = useState(false);
  const { users, isLoading } = useUsers();
  const { addFeatureFlag } = useFeatureFlags();
  const history = useHistory();

  const handleAddFeatureFlag = async (name) => {
    setIsAddingFF(true);
    try {
      await waitTime(2000);
      await addFeatureFlag({ name });
      message.success(`Successfully added ${name}!`);
    } catch (err) {
      message.error(err[0]);
    }
    setIsAddingFF(false);
    setIsFFModalVisible(false);
  };

  const handleAddFeatureFlagButtonPressed = () => {
    setIsFFDrawerVisible(false);
    setIsFFModalVisible(true);
  };

  const filteredUsers = users?.filter(
    (user) =>
      user.coachUser?.firstName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      user.coachUser?.lastName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    {
      title: "First name",
      key: "firstName",
      dataIndex: "firstName",
    },
    {
      title: "Last name",
      key: "lastName",
      dataIndex: "lastName",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Roster Spots",
      key: "athleteSpots",
      dataIndex: "athleteSpots",
    },
  ];

  return (
    <>
      <AddFeatureFlagModal
        isVisible={isFFModalVisible}
        onCancel={() => setIsFFModalVisible(false)}
        isAddingFeatureFlag={isAddingFF}
        onAddFeatureFlag={handleAddFeatureFlag}
      />
      <FeatureFlagDrawer
        isVisible={isFFDrawerVisible}
        onClose={() => setIsFFDrawerVisible(false)}
        onAddFeatureFlag={handleAddFeatureFlagButtonPressed}
      />
      <Container noPadding>
        <PageHeader
          title="Users"
          className="site-page-header"
          extra={[
            <Button
              type="secondary"
              icon={<EditOutlined />}
              onClick={() => setIsFFDrawerVisible(true)}
            >
              Manage Feature Flags
            </Button>,
          ]}
        />
      </Container>
      <Container>
        <Search
          style={{ width: "100%" }}
          placeholder="Enter email, first name, etc..."
          allowClear
          onSearch={(query) => setSearchQuery(query)}
          enterButton
        />
      </Container>
      <Container>
        <Table
          rowKey={(user) => user.id}
          loading={isLoading}
          columns={columns}
          dataSource={filteredUsers}
          rowClassName={styles.tableRow}
          onRow={(user) => {
            return {
              onClick: (e) => {
                e.stopPropagation();
                history.push(`/users/${user.id}`);
              },
            };
          }}
        />
      </Container>
    </>
  );
};

export default Users;
