import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Select,
  Space,
  DatePicker,
  Input,
  Radio,
  InputNumber,
  Upload,
} from "antd";
import { Link } from "react-router-dom";
import eventTypeGroups from "../../../events/constants/eventTypeGroups";
import useEventTypes from "../../../events/hooks/useEventTypes";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const { RangePicker } = DatePicker;

const sexes = ["Male", "Female", "Unspecified"];

const AddCompetitionForm = ({ isLoading, onNextStep }) => {
  const [form] = Form.useForm();
  const [isLocal, setIsLocal] = useState(true);
  const [shouldShowDistance, setShouldShowDistance] = useState(true);
  const [shouldShowResultBasedForms, setShouldShowResultBasedForms] =
    useState(true);

  const { eventTypes } = useEventTypes(
    eventTypeGroups.classic,
    true,
    false,
    true
  );
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      type: "local",
      submissionType: "result",
      sex: "Unspecified",
    });
  }, []);

  useEffect(() => {
    if (eventTypes) form.setFieldsValue({ eventType: eventTypes[0].enumValue });
  }, [eventTypes]);

  const handleEventTypeChange = (eventEnumValue) => {
    if (eventEnumValue === "Dash" || eventEnumValue === "Flying")
      setShouldShowDistance(true);
    else setShouldShowDistance(false);
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const getFile = (e) => {
    if (Array.isArray(e)) return e;
    return e && e.fileList;
  };

  let ZipCodeForm = null;
  if (isLocal) {
    ZipCodeForm = (
      <Form.Item
        name="zipCode"
        label="Zip Code"
        rules={[
          {
            required: true,
            message: "Zipcode is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
    );
  }

  let DistanceForm = null;
  if (shouldShowDistance)
    DistanceForm = (
      <Form.Item name="distance" label="Distance">
        <InputNumber style={{ width: "100%" }} disabled={!shouldShowDistance} />
      </Form.Item>
    );

  let ResultBasedForms = null;
  if (shouldShowResultBasedForms)
    ResultBasedForms = (
      <>
        <Form.Item label="Event Type" name="eventType">
          <Select
            showSearch
            defaultActiveFirstOption={false}
            onChange={handleEventTypeChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {eventTypes?.map((eventType) => (
              <Option
                key={eventType.enumValue}
                value={eventType.enumValue}
              >{`${eventType.displayValue}`}</Option>
            ))}
          </Select>
        </Form.Item>
        {DistanceForm}
        <Form.Item
          name="isMetric"
          label="Units"
          rules={[{ required: true, message: "Please pick a unit!" }]}
        >
          <Radio.Group>
            <Radio.Button value={false}>Imperial</Radio.Button>
            <Radio.Button value={true}>Metric</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </>
    );

  const handleFormFinish = (values) => {
    const {
      date,
      name,
      submissionType,
      eventType,
      distance,
      sponsorshipName,
      sex,
      sponsorshipDescription,
      zipCode,
      isMetric,
      logo,
    } = values;

    let startDate = null,
      endDate = null;
    if (date) {
      startDate = date[0]?.startOf("day").toISOString();
      endDate = date[1]?.endOf("day").toISOString();
    }

    const competition = {
      name,
      sex,
      sponsorshipName,
      sponsorshipDescription,
      zipCode,
      startDate,
      endDate,
    };

    if (submissionType === "result") {
      competition.eventType = eventType;
      competition.distance = distance;
      competition.isMetric = isMetric;
    }

    onNextStep(competition, logo);
  };

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <Form layout={"vertical"} onFinish={handleFormFinish} form={form}>
      <Form.Item
        name="type"
        label="Type"
        rules={[{ required: true, message: "Please pick a type" }]}
      >
        <Radio.Group
          onChange={(e) =>
            e.target.value === "local" ? setIsLocal(true) : setIsLocal(false)
          }
        >
          <Radio.Button value={"local"}>Local</Radio.Button>
          <Radio.Button value={"global"}>Global</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="submissionType"
        label="Submission Type"
        rules={[{ required: true, message: "Please pick a type" }]}
      >
        <Radio.Group
          onChange={(e) =>
            setShouldShowResultBasedForms(
              e.target.value === "result" ? true : false
            )
          }
        >
          <Radio.Button value={"result"}>Result</Radio.Button>
          <Radio.Button value={"report"}>Report</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: "Name is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      {ResultBasedForms}

      <Form.Item
        name="logo"
        label="Sponsorship Logo"
        valuePropName="fileList"
        getValueFromEvent={getFile}
      >
        <Upload
          listType="picture-card"
          getValueFromEvent={getFile}
          onChange={handleChange}
          beforeUpload={(file) => false}
          showUploadList={{
            showPreviewIcon: false,
          }}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
      </Form.Item>
      <Form.Item name="sponsorshipName" label="Sponsorship Name">
        <Input />
      </Form.Item>
      <Form.Item name="sponsorshipDescription" label="Sponsorship Description">
        <Input />
      </Form.Item>
      <Form.Item name="sex" label="Athlete's Sex">
        <Select>
          {sexes.map((sex) => {
            return <Option key={sex}>{sex}</Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={`date`}
        label={`Date Range`}
        rules={[
          {
            required: true,
            message: "Date range is required",
          },
        ]}
      >
        <RangePicker style={{ width: "100%" }} />
      </Form.Item>
      {ZipCodeForm}
      <Form.Item>
        <Space>
          <Link to="/competitions">
            <Button>Cancel</Button>
          </Link>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Next
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AddCompetitionForm;
