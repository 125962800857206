import React from "react";

import { Modal, Form, Input, Typography } from "antd";
const { Text, Link } = Typography;

const DeleteUserModal = ({
  isVisible,
  userEmail,
  onDeleteUser,
  onCancel,
  isDeletingUser,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={isVisible}
      title="Delete User"
      okText="Delete"
      cancelText="Cancel"
      onCancel={onCancel}
      confirmLoading={isDeletingUser}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onDeleteUser(values);
          })
          .catch((info) => {});
      }}
      okType={"danger"}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
        requiredMark={false}
      >
        <Form.Item
          name="email"
          label={
            <Text>
              Type <Text strong>{userEmail}</Text> to confirm delete
            </Text>
          }
          rules={[
            () => ({
              validator(_, value) {
                if (value === userEmail) return Promise.resolve();

                return Promise.reject(
                  new Error(
                    "Sorry please enter the text exactly as displayed to confirm"
                  )
                );
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DeleteUserModal;
