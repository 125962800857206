import React, { createContext, useContext, useState } from "react";

const MenuContext = createContext(["1"]);

const MenuProvider = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState(["1"]);
  return (
    <MenuContext.Provider value={{ selectedMenu, setSelectedMenu }}>
      {children}
    </MenuContext.Provider>
  );
};

const useMenu = () => {
  const { selectedMenu, setSelectedMenu } = useContext(MenuContext);
  return { selectedMenu, setSelectedMenu };
};

export { MenuProvider, useMenu };
