import React from "react";

const Container = ({ style, noPadding, noMargin, children }) => {
  return (
    <div
      style={{
        background: "white",
        margin: noMargin ? 0 : 16,
        padding: noPadding ? 0 : 24,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Container;
