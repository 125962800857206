import React from "react";

import { Modal, Form, Input } from "antd";

const AddFeatureFlagModal = ({
  isVisible,
  onAddFeatureFlag,
  onCancel,
  isAddingFeatureFlag,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={isVisible}
      title="Add FeatureFlag"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      confirmLoading={isAddingFeatureFlag}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const { name } = values;
            onAddFeatureFlag(name);
          })
          .catch((info) => {});
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddFeatureFlagModal;
