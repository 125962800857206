import React from "react";

import { Modal, Table } from "antd";

const EntryModal = ({ entries, isVisible, onCancel }) => {
  const columns = [
    {
      title: "Rank",
      dataIndex: "rank",
    },
    {
      title: "Value",
      dataIndex: "value",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Location",
      dataIndex: "location",
    },
  ];

  return (
    <Modal visible={isVisible} onCancel={onCancel} title="Competition Entries">
      <Table
        rowKey={(entry) => entry.id}
        columns={columns}
        dataSource={entries}
      />
    </Modal>
  );
};

export default EntryModal;
