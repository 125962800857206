import React, { useState } from "react";
import { Alert } from "antd";

const useAccessAlert = (type) => {
  const [alertMessage, setAlertMessage] = useState(null);

  let alert = null;
  if (alertMessage) {
    alert = (
      <Alert
        style={{ marginBottom: 16 }}
        message={alertMessage}
        type={type}
        showIcon
      />
    );
  }
  return [alert, setAlertMessage];
};

export default useAccessAlert;
