import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Form, Input, Button, Select } from "antd";
import {
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import waitTime from "../../../../common/utils/waitTime";
import { useAuthContext } from "../../../../common/contexts/AuthContext";

import styles from "./Signup.module.scss";
import useAccessAlert from "../../hooks/useAccessAlert";
import generateAccessErrorMessage from "../../utils/accessErrorMessageHandler";
import logo from "../../../../assets/logo.svg";


const Signup = () => {
  const [alert, setAlertMessage] = useAccessAlert("error");
  const [isLoading, setIsLoading] = useState(false);
  const { signUp } = useAuthContext();
  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setAlertMessage(null);
    setIsLoading(true);
    const { email, firstName, lastName, password } = values;
    try {
      await signUp(email, password);
      //   const user = {
      //     email,
      //     firstName,
      //     lastName,
      //     sportingLevelId,
      //     sportIds,
      //     address,
      //   };
      //   await createUser(user);
      await waitTime(2000);
      history.push("/");
    } catch (error) {
      setAlertMessage(generateAccessErrorMessage(error.code));
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.login}>
        <div className={styles.logo}>
          <img src={logo} style={{ height: 100, width: 100 }} alt="Logo" />
        </div>
        {alert}
        <Form
          name="normal_login"
          className={styles.loginForm}
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Confirm Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.signupFormButton}
              loading={isLoading}
            >
              Sign up
            </Button>
            Have an account already? <Link to="/login">Login</Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Signup;
