import React, { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, message } from "antd";
import {
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  MailOutlined,
} from "@ant-design/icons";
import waitTime from "../../../../common/utils/waitTime";
import { useAuthContext } from "../../../../common/contexts/AuthContext";
import styles from "./Login.module.scss";
import generateAccessErrorMessage from "../../utils/accessErrorMessageHandler";
import useAccessAlert from "../../hooks/useAccessAlert";
import { sendResetEmail } from "../../../../common/services/authService";
import logo from "../../../../assets/logo.svg";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuthContext();
  const [alert, setAlertMessage] = useAccessAlert("error");
  const history = useHistory();

  const onFinish = async (values) => {
    setAlertMessage(null);
    setIsLoading(true);
    const { email, password } = values;
    try {
      await login(email, password);
      await waitTime(2000);
      history.push("/");
    } catch (error) {
      setAlertMessage(generateAccessErrorMessage(error.code));
    }
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.login}>
        <div className={styles.logo}>
          <img src={logo} style={{ height: 100, width: 100 }} alt="Logo" />
        </div>
        {alert}
        <Form
          name="normal_login"
          className={styles.loginForm}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Link to={"/forgotpassword"} className={styles.loginFormForgot}>
              Forgot password
            </Link>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.loginFormButton}
              loading={isLoading}
            >
              Log in
            </Button>
            Or <Link to="/signup">register now!</Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
