import axios from "axios";
import eventTypeGroups from "../constants/eventTypeGroups";
import useSWR from "swr";

const path = "/event/type";

const useEventTypes = (
  type = eventTypeGroups.classic,
  includeRast = true,
  includeCustom = true,
  includeLap = true
) => {
  const { data: eventTypes, error } = useSWR(
    `${path}?type=${type}&includeCustom=${includeCustom}&includeRast=${includeRast}&includeLap=${includeLap}`,
    axios.get
  );

  return {
    eventTypes,
    isLoading: !error && !eventTypes,
    isError: error,
  };
};

export default useEventTypes;
