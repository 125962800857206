import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const path = "/integrationuser";

const useIntegrationUsers = () => {
  const { data: integrationUsers, error } = useSWR(path, axios.get);

  return {
    integrationUsers,
    isLoading: !error && !integrationUsers,
  };
};

export default useIntegrationUsers;
