import React, { useEffect, useState } from "react";
import { Button, Form, Input, PageHeader, Space, message, Upload } from "antd";
import { useParams } from "react-router-dom";
import Container from "../../../../common/components/Container/Container";
import { Link, useHistory } from "react-router-dom";
import waitTime from "../../../../common/utils/waitTime";
import usePartners from "../../hooks/usePartners";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";

const PartnerDetail = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [partner, setPartner] = useState(null);
  const { updatePartner, uploadLogo } = usePartners();
  const [isUpdating, setIsUpdating] = useState(false);
  const history = useHistory();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const getPartner = async () => {
      try {
        const partner = await axios.get(`/partner/${id}`);
        setPartner(partner);
      } catch (ex) {}
    };
    getPartner();
  }, []);

  const handleUpdatePartner = async (values) => {
    setIsUpdating(true);
    await waitTime(2000);
    try {
      const {
        description,
        learnMoreUrl,
        displayOrder,
        signUpUrl,
        partnerLogoUrl,
      } = values;
      let logoUrl = partner.logoUrl;

      if (partnerLogoUrl && partnerLogoUrl.length === 0) {
        logoUrl = null;
      } else if (
        partnerLogoUrl &&
        partnerLogoUrl.length &&
        partnerLogoUrl.length > 0 &&
        partnerLogoUrl[0].uid !== "-1"
      ) {
        const imageFile = partnerLogoUrl[0].originFileObj;
        logoUrl = await uploadLogo(imageFile);
      }
      const updatePartnerDto = {
        partnerId: id,
        description,
        learnMoreUrl,
        signUpUrl,
        displayOrder,
        logoUrl,
      };
      await updatePartner(id, updatePartnerDto);
      message.success("Successfully updated partner!");
      history.push("/partner");
    } catch (err) {
      console.log(err);
      message.error("There was a problem updating the partner");
    }
    setIsUpdating(false);
  };

  useEffect(() => {
    if (partner) {
      const fields = {
        name: partner.name,
        integrationUser: partner.integrationUser,
        description: partner.description,
        learnMoreUrl: partner.learnMoreUrl,
        displayOrder: partner.displayOrder,
        signUpUrl: partner.signUpUrl,
      };

      if (partner.logoUrl) {
        const existingLogo = [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: partner.logoUrl,
          },
        ];
        fields.partnerLogoUrl = existingLogo;
        setFileList(existingLogo);
      }

      form.setFieldsValue(fields);
    }
  }, [partner]);

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <>
      <Container noPadding>
        <PageHeader className="site-page-header" title={"Partner Detail"} />
      </Container>
      <Container>
        <Form
          form={form}
          name="editUser"
          requiredMark={true}
          layout={"vertical"}
          scrollToFirstError
          onFinish={handleUpdatePartner}
        >
          <Form.Item name="name" label="Name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="integrationUser" label="Integration User">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            name="partnerLogoUrl"
            label="Logo"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) return e;
              return e && e.fileList;
            }}
          >
            <Upload
              listType="picture-card"
              onChange={({ fileList: newFileList }) => setFileList(newFileList)}
              beforeUpload={(file) => false}
              showUploadList={{
                showPreviewIcon: false,
              }}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input />
          </Form.Item>
          <Form.Item name="displayOrder" label="Display Order">
            <Input />
          </Form.Item>
          <Form.Item name="learnMoreUrl" label="Learn More URL">
            <Input />
          </Form.Item>
          <Form.Item name="signUpUrl" label="Sign Up URL">
            <Input />
          </Form.Item>
          <Form.Item>
            <Space>
              <Link to="/partner">
                <Button>Cancel</Button>
              </Link>
              <Button type="primary" htmlType="submit" loading={isUpdating}>
                Update
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Container>
    </>
  );
};

export default PartnerDetail;
