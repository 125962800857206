import React from "react";
import { Redirect, Route } from "react-router";
import { useAuthContext } from "../contexts/AuthContext";
import DashboardLayout from "../layouts/DashboardLayout/DashboardLayout";

const AuthRoute = ({ component: Component, ...props }) => {
  const { currentUser } = useAuthContext();
  return (
    <Route
      {...props}
      render={(props) => {
        return currentUser ? (
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default AuthRoute;
